@import "/src/styles/variables";

.App-Web-Phone .app-layout-component {
  flex: auto;
  display: grid;
  grid:
    "modes header       " auto
    "modes pages        " auto
    "modes page-content " 1fr
    / auto 1fr;
  overflow: auto;

  .main-menu-component {
    grid-area: modes;
    border-right: 2px solid $border-color;
  }

  .header-component {
    grid-area: header;
  }

  .page-tabs-component {
    grid-area: pages;

    .p-tabview-panels {
      display: none;
    }
  }

  .page-content {
    grid-area: page-content;
    overflow: auto;

    > .p-splitter {
      overflow: auto;
      flex: auto;
    }
  }
}

.App-Web-Phone .app-error-msg {
  font-style: italic;
  margin-top: 2rem;
}
