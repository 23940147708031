@import "/src/styles/variables"

.App-Web-Phone .activity-page-unread-tab

  .p-datatable-table
    width: 500px

    tr:last-child td
      font-weight: bold

    tr:hover
      color: $primary-color
      cursor: pointer

    th
      text-align: center
    td
      text-align: right