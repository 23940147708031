@import "/src/styles/variables.sass";

.App-Web-Phone .sms-templates-component {
  padding: 1rem;

  > .p-splitter {
    height: 100%;
  }

  .templates-panel {
    position: relative;
  }

  .search-component {
    grid-area: search;
  }

  .template-details {
    margin: 1rem;
  }

  .p-inputtext, .p-inputtextarea {
    width: 100%;
  }

  .loader-component {
    grid-area: loading;
    padding: 0.5rem;
  }

  .templates {
    overflow: auto;
    margin: 0;
  }

  .template {
    display: grid;
    grid:
      'name'
      'desc' 32px
      / 1fr auto;
    border-bottom: solid 1px #ddd;
    padding: 8px;
    cursor: pointer;

    &.selected {
      background: #4164FB;
      color: #fff;
    }
  }

  .name {
    grid-area: name;
    font-weight: bold;
    font-size: 18px;
  }

  .description {
    grid-area: desc;
    overflow: auto;
    margin-top: 4px;
  }

  .new-template-btn {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  .new-template input {
    width: 100%;
    font-weight: bold;
  }
}
