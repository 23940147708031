@import "styles/variables";

.App-Web-Phone .compose-sms-component {
  padding: 0.5rem;
  border-top: solid 1px $border-color;
  display: flex;
  align-items: center;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .invisible {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .message-area {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .text-message {
    display: flex;
  }

  .p-inputtext {
    flex: auto;
    font-size: 18px;
  }

  a {
    color: $primary-color;

    &.red {
      color: $red-color;
    }
  }

  .template {
    width: 100%;
  }

  .buttons {
    flex: none;
    gap: 1rem;
    grid-auto-flow: row;
    align-self: flex-end;
    position: relative;
    min-width: 32px;

    .p-button {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0;
      display: flex;
      justify-content: center;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    font-size: 14px;
  }

  .spacer {
    flex: auto;
  }

  .p-speeddial {
    position: absolute;
    bottom: 0;
    background: transparent;
  }

  .no-first-btn {
    margin-top: 54px;
  }

  .attachment-card .p-card-content {
    display: flex;
    gap: 8px;
    padding: 0;
  }

  .filesize {
    font-weight: bold;
  }

  .branding-sms-note {
    font-size: 12px;
  }
}
