@import 'styles/variables';

.App-Web-Phone .sms-campaigns-component {

  .messages {
    flex-direction: column-reverse;

    >ul {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .no-messages {
    font-style: italic;
    align-self: center;
  }
}
