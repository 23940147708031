@import "/src/styles/variables.sass";

.App-Web-Phone .sms-list-component {
  padding: 1rem;

  > .p-splitter {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .p-tabview, .p-tabview-panels, .p-tabview-panel {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: auto;
  }
}
