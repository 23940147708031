@import 'styles/variables';

.App-Web-Phone .chat-campaign-message-component {
  padding: 1rem;
  max-width: 500px;
  display: grid;
  grid:
    'content  content' 1fr
    'source   time' auto
    / 1fr 1fr;

  .message-content {
    grid-area: content;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: solid 1px fade-out($color: $dark-color, $amount: 0.9);
    background: fade-out($color: $dark-color, $amount: 0.9);
    font-size: 16px;
  }

  .my-number {
    grid-area: source;
    padding: 0 0.5rem;
  }

  .time {
    grid-area: time;
    text-align: right;
    padding: 0 0.5rem;
  }

  .my-number, .time {
    font-size: 12px;
    color: #aaa;
  }

  .message-body {
    color: #888;
  }

  .title {
    font-weight: bold;
    margin-bottom: 4px;
  }

}
