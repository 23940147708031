@import "/src/styles/variables.sass";

.App-Web-Phone .active-call-component {
  align-items: center;
  padding: 1rem;
  justify-content: center;

  .content {
    width: 400px;
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;
  }

  .contact-card-component {
    min-width: 200px;
  }

  .last-message {
    margin-top: 8px;
  }

  .my-number {
    margin-top: 4px;
    padding-left: 8px;
  }

  .contact-number {
    font-size: 18px;
    font-weight: bold;
  }

  .session-controls {
    display: grid;
    gap: 2rem;
    justify-items: center;
  }

  .button-row {
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
  }

  .error-toast {
    background: #fff;
    color: $txt-black;
    .Toastify__close-button {
      color: $txt-black;
    }
    .error-progress {
      background: #e74c3c;
    }
  }

  .p-button.p-button-rounded {
    width: 52px;
    height: 52px;
    font-size: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .call-session-container {
    max-width: 100%;
    width: 100%;
  }
}
