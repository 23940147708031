@import "/src/styles/variables"

.App-Web-Phone .config-page-brand-management-tab
  display: flex

  form
    flex: auto

  .button
    min-width: 100px

  .form-group
    display: flex
    align-items: center

    label
      width: 80px
      margin-right: 10px

  .color-textbox
    width: 70px
    margin-left: 8px

  .upload
    margin-right: 16px

  .logo
    flex: auto

    img
      object-fit: scale-down
      max-height: 100px
      max-width: 100%

  .template-panel
    height: 200px
    width: 100%
    display: flex

    &.hidden
      display: none

  .warning
    color: $red-color
    margin: 16px

  textarea
    resize: none
    flex: auto

  .editor
    flex: auto
    border: 1px solid #ced4da
    padding: 0.5rem
    width: 400px
    min-width: 400px
    overflow: auto

  .description
    color: $dark-color
    margin-left: 16px

  .placeholder
    color: black
    font-size: 18px