@import "/src/styles/variables.sass"

.App-Web-Phone .greetings-component
  $greeting-card-size: 400px
  
  p
    padding-left: 1rem
    padding-right: 1rem

  .p-card .p-card-content
    padding: 0

  .greetings-panel
    display: flex
    overflow: auto

  .greetings
    min-width: calc($greeting-card-size + 2rem)
    position: relative
    min-height: 80px // so that the new greeting button doesn't get cropped

  .no-greetings
    font-style: italic
    margin: 1rem

  .numbers
    flex: auto
    overflow: auto
    display: flex
    flex-wrap: wrap
    align-items: flex-start

  .greeting-card, .number-card
    margin: 1rem
    background: #fafafa
    width: $greeting-card-size

    audio
      width: 100%

  .number-card
    width: 300px

    &.active
      color: $primary-color

    .p-card-content
      display: flex
      align-items: center
      gap: 0.5rem

    svg
      font-size: 24px
      color: $red-color

  .drop-zone
    border: dashed 1px $border-color
    padding: 1rem
    font-size: 18px
    opacity: 0.5
    background: #f8f8f8

  .new-greeting-btn
    position: absolute
    top: 0.75rem
    right: 0.5rem
    width: 3rem
    height: 3rem
    cursor: pointer

.p-dialog
  .greetings-flex-row
    display: flex
    align-items: center
    justify-content: space-between

    + .greetings-flex-row
      margin-top: 1rem

  .submit-new-greeting-btn
    margin-left: 1rem