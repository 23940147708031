@import "/src/styles/variables";

.App-Web-Phone .search-component {
  border-bottom: solid 1px $border-color;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;

  input.p-inputtext {
    font-size: 16px;
    border: none;
  }
}
