.App-Web-Phone .login-page
  display: grid
  align-items: center
  justify-content: center

  .button
    width: 100%
    margin-top: 8px

  .form-content
    display: flex
    flex-direction: column
    align-items: center
    margin: 24px calc(50% - 130px)

    .form-group
      width: 100%