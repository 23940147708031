@import 'variables'
  
html, body, .App-Web-Phone
  height: 100%
  min-height: 100%
  max-height: 100%
  margin: 0
  padding: 0
  border: 0
  overflow: auto
  overscroll-behavior-x: none
  overscroll-behavior-y: none

html, body, #root, .content, .App-Web-Phone
  flex: auto
  overflow: auto
  display: flex
  flex-direction: column

#root
  margin: 0 auto

.App-Web-Phone.auto-flex,
.App-Web-Phone .auto-flex,
.p-dialog .auto-flex
    display: flex
    flex-direction: column
    flex: auto
    overflow: auto

.App-Web-Phone
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 16px
  
  h1, h2, h3, h4, h5
    margin-top: 5px
    text-shadow: 0 0 1px #f6f6f6
    font-weight: normal

  &, *
    box-sizing: border-box

  code
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

  ul
    list-style: none
    padding: 0

    &.three-columns
      column-count: 3

  a
    color: inherit
    text-decoration: none

    &:hover
      text-decoration: underline

  .section
    border: solid 1px #ccc
    border-radius: 0.5rem
    padding: 1.5rem 1rem 1rem
    margin: 2rem 0 4rem
    position: relative
    background: #f8f8f8

    .section-header
      position: absolute
      height: 50px
      top: -25px
      left: 1rem
      right: 1rem
      background: transparent
      display: grid
      grid: 'toggle title space' 1fr / auto auto 1fr
      grid-auto-flow: column
      align-items: center

      h3
        display: inline-block
        grid-area: title
        background: #f8f8f8
        padding: 0 0.5rem
        margin: 0
        font-size: 18px

      .chevron-toggle
        grid-area: toggle
        cursor: pointer
    
    .section
      margin: 1rem 0 2rem
      background: #f0f0f0
      
      .section-header h3
        background: #f0f0f0

  .form-group
    margin-bottom: 1rem

  .form-items
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem

    &.three-columns
      grid-template-columns: 1fr 1fr 1fr


  label
    display: block
    margin-bottom: 0.5rem

  .buttons
    display: grid
    grid-auto-flow: column
    grid-gap: 1rem

    &.btn-left
      justify-content: left

    &.btn-right
      justify-content: right

  .button, .csv-reader-input
    background: $primary-color
    color: white
    border: none
    padding: 1rem 2rem
    border-radius: 4px
    font-size: 18px
    cursor: pointer
    min-width: 150px
    white-space: nowrap
    justify-self: left

    &[disabled], &.disabled
      background: silver
      cursor: not-allowed

    &.btn-right
      justify-self: right

  .link-button
    background: transparent
    color: inherit
    font: inherit
    border: none
    cursor: pointer
    color: $primary-color
  
    &:hover
      text-decoration: underline

  .csv-reader-input
    position: relative
    overflow: hidden

    input[type=file]
      position: absolute
      left: 0
      top: 0
      right: 0
      bottom: 0
      opacity: 0
      cursor: pointer

    label
      margin-bottom: 0

  .clickable
    cursor: pointer

  .red
    color: #a00

  .react-datepicker-wrapper
    display: block

  .react-datepicker
    font-size: 1rem

  .react-datepicker-wrapper input,
  input.textbox,
  .textbox input
    width: 100%
    border: 1px solid hsl(0, 0%, 80%)
    border-radius: 4px
    height: 40px
    padding: 2px 8px

  .chevron-toggle
    transition: all ease 0.3s
    display: grid
    align-items: center
    width: 30px
    height: 30px
    justify-content: center
    background: white
    border: solid 1px silver
    border-radius: 100%

    &.rotate180
      transform: rotate(180deg)

  table
    width: 100%
    border: 1px solid #ccc
    border-collapse: collapse
    margin: 1rem 0

  tr:nth-of-type(2n)
    background: #f4f4f4

  tr.selected
    background: #2684ff
    color: white

  th
    border-bottom: 1px solid #ccc
    font-weight: normal
    text-align: left
    color: #555
    text-shadow: 0 0 1px #f6f6f6
    font-size: 18px

  th, td
    padding: 0.5rem
    border-left: 1px solid #ccc
    border-right: 1px solid #ccc

  .remove-btn
    background: #800

.App-Web-Phone, .p-dialog
  .upload
    position: relative
    cursor: pointer

    input[type="file"]
      position: absolute
      z-index: 1
      opacity: 0
      left: 0
      right: 0
      top: 0
      bottom: 0

  .form-input-group
    margin-top: 32px