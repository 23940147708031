@import "styles/variables";

.App-Web-Phone .compose-sms-campaign-component {
  padding: 0.5rem;
  border-top: solid 1px $border-color;
  display: flex;
  align-items: center;
  gap: 1rem;

  .desc {
    font-size: 14px;
  }

  .p-inputtext {
    width: 100%;
    font-size: 18px;
  }

  a {
    color: $primary-color;

    &.red {
      color: $red-color;
    }
  }

  .input-name {
    font-weight: bold;
  }

  .input-body {
    max-height: 300px;
  }

  .branding-sms-note {
    font-size: 12px;
  }

  .btn-send {
    padding: 12px;
    cursor: pointer;
  }
}
