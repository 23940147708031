@keyframes rotate
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

.App-Web-Phone .loader-component
  font-size: 14px
  color: #888
  padding: 1rem

  svg
    animation: rotate linear 1s infinite
    color: #dbdbdb
