@import "/src/styles/variables";

.App-Web-Phone .contact-card-component {
  display: grid;
  grid:
    "photo name         date-time"
    "photo last-message unread" 32px
    / 48px 1fr auto;
  border-bottom: solid 1px #ddd;
  padding: 8px;
  column-gap: 8px;
  cursor: pointer;
  position: relative;

  &.sms-list-mode {
    grid:
      "photo check     name"
      "photo variables variables"
      / auto 1fr;
  }

  &.selected:not(.sms-list-mode) {
    background: #4164fb;
    color: #fff;
  }

  .contact-photo {
    grid-area: photo;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);

    img {
      object-fit: scale-down;
      width: 100%;
      height: 100%;
    }
  }

  .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: larger;
    font-weight: 500;
  }

  .check {
    grid-area: check;
    margin: 0 0.5rem;
  }

  .name {
    grid-area: name;
    font-weight: bold;
    font-size: 18px;
  }

  .date-time {
    grid-area: date-time;
    text-align: right;
  }

  .last-message {
    grid-area: last-message;
    overflow: auto;
  }

  .ok-btn {
    grid-area: btn;
  }

  .manage-contact {
    position: absolute;
    right: 8px;
    bottom: 8px;
    cursor: pointer;
    display: flex;
  }

  .contact-pin {
    display: inline;
    padding: 4px;
  }

  .edit-user {
    margin: 4px;
  }

  .unread-count {
    padding: 1px 6px 2px;
    background: $primary-color;
    color: $txt-white;
    border-radius: 16px;
  }

  > .form-fields-component {
    grid-area: variables;
  }
}

.App-Web-Phone_edit-contact-modal {
  .form-image-upload-group {
    display: flex;
    align-items: center;
    margin-top: 32px;
  }

  .upload {
    margin-right: 16px;
  }

  .contact-photo-preview {
    flex: auto;

    img {
      object-fit: scale-down;
      max-height: 100px;
      max-width: 100px;
    }
  }

  .btn-standard-width {
    width: 80px;
  }

  .clear-photo {
    display: flex;
    justify-content: flex-end;
  }
}
