@import "/src/styles/variables.sass"

.App-Web-Phone .call-procedures-component
  $card-size: 400px
  
  p
    padding-left: 1rem
    padding-right: 1rem

  .p-card .p-card-content
    padding: 0

  .call-procedures-panel
    display: flex
    overflow: auto

  .call-procedures
    min-width: calc($card-size + 2rem)

  .no-call-procedures
    font-style: italic
    margin: 1rem

  .numbers
    flex: auto
    overflow: auto
    display: flex
    flex-wrap: wrap
    align-items: flex-start

  .call-procedure-card, .number-card
    margin: 1rem
    background: #fafafa
    width: $card-size

    audio
      width: 100%

  .number-card
    width: 300px

    &.active
      color: $primary-color

    .p-card-content
      display: flex
      align-items: center
      gap: 0.5rem

    svg
      font-size: 24px
      color: $red-color

  .drop-zone
    border: dashed 1px $border-color
    padding: 1rem
    font-size: 18px
    opacity: 0.5
    background: #f8f8f8
