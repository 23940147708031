@import 'styles/variables';

.App-Web-Phone .messages-component {

  .messages {
    flex-direction: column-reverse;
  }

  .new-number-area {
    flex: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    h3 {
      font-weight: bold;
    }
  }

  .no-contact-selected {
    font-style: italic;
    margin: 1rem;
  }
}
