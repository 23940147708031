@import "/src/styles/variables";

.App-Web-Phone .sms-lists-component {
  font-size: 16px;
  display: grid;
  grid:
    'search' auto
    'loading' auto
    'contacts' 1fr
    / 1fr;
  height: 100%;
  position: relative;

  .search-component {
    grid-area: search;
  }

  .dialer-btn {
    zoom: 1.2;
    cursor: pointer;
  }

  .loader-component {
    grid-area: loading;
    padding: 0.5rem;
  }

  .contacts {
    grid-area: contacts;
    overflow: auto;
    margin: 0;
  }

  .contact {
    display: grid;
    grid:
      'name'
      'desc' 32px
      / 1fr auto;
    border-bottom: solid 1px #ddd;
    padding: 8px;
    cursor: pointer;

    &.selected {
      background: #4164FB;
      color: #fff;
    }
  }

  .name {
    grid-area: name;
    font-weight: bold;
    font-size: 18px;
  }

  .description {
    grid-area: desc;
    overflow: auto;
    margin-top: 4px;
  }

  .new-contact-btn {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  .new-contact input {
    width: 100%;
    font-weight: bold;
  }

  .no-contact-lists {
    font-style: italic;
    margin: 1rem;
  }
}