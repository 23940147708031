@import "/src/styles/variables.sass";

.App-Web-Phone .gallery-component {
  padding: 1rem;

  .large-image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .thumbnail {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
  }

  &, .p-galleria, .p-galleria-content {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: auto;
  }

  .p-galleria-item-wrapper {
    flex: auto;
    overflow: auto;
  }

  .contact-name {
    cursor: pointer;
  }
}
