@import "/src/styles/variables"

.page-tabs-component
  font-size: 16px
  padding: 0 1rem

  .main-menu
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around

  .mode-btn
    font-size: 18px
    line-height: 16px
    text-align: center
    cursor: pointer
    min-width: 60px
    padding: 2rem 1rem

    > div
      font-size: 14px

    &.active
      color: $primary-color
      font-weight: bold
