@import "/src/styles/variables"

.main-menu-component
  font-size: 16px
  min-width: 120px

  .main-menu
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around

  .mode-btn
    font-size: 24px
    line-height: 24px
    text-align: center
    cursor: pointer
    min-width: 60px
    padding: 2rem 1rem
    position: relative
    width: 100%

    > div
      font-size: 14px

    &.active
      color: $primary-color
      font-weight: bold

  .unread-messages
    position: absolute
    right: 8px
    top: 16px
    border-radius: 16px
    background: $primary-color
    color: $txt-white
    padding: 0 8px