@import "/src/styles/variables";

.App-Web-Phone .form-fields-component {

  .form-fields {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
  }

  .loader {
    width: 100%;
  }

  .add-new-field-btn {
    display: flex;
    align-items: flex-end;
    width: 250px;
  }

  h4 {
    font-weight: bold;
    display: block;
  }

  .variable-count {
    font-size: 0.8rem;
    color: #999;
    position: relative;
    top: -20px;
  }
}
