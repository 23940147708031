.App-Web-Phone

  .layout-component
    width: 100%
    margin: 0 auto
    
    > *
      flex: auto

  &.self-hosted
    margin-right: 15px
    margin-left: 15px
    @media (min-width: 768px)
      width: 750px
    @media (min-width: 992px)
      width: 970px
    @media (min-width: 1200px)
      width: 1170px
