@import 'styles/variables';

.App-Web-Phone .media-message-content-component {

  .image img {
    width: 400px;
    max-height: 300px;
    object-fit: scale-down;
    object-position: left;
  }

  .dimensions {
    font-size: 12px;
    position: relative;
    top: 2px;
    left: 40px;
    color: #444;
  }

  .download-btn.p-button:not(.p-button-success):not(.p-button-danger):not(.p-button-secondary):not(:hover) {
    background: transparent;
    color: inherit;
  }

}
