@import "/src/styles/variables";

.App-Web-Phone .contacts-component {
  font-size: 16px;
  display: grid;
  grid:
    'search' auto
    'loading' auto
    'contacts' 1fr
    / 1fr;
  height: 100%;
  position: relative;

  .search-component {
    grid-area: search;
  }

  .dialer-btn {
    zoom: 1.2;
    cursor: pointer;
  }

  .loader-component {
    grid-area: loading;
    padding: 0.5rem;
  }

  .contacts {
    grid-area: contacts;
    overflow: auto;
    margin: 0;
  }

  .contacts.checkable .contact {
    grid:
      'check name         date-time'
      'check last-message unread' 32px
      / 30px 1fr auto;
  }

  .check {
    grid-area: check;
  }

  .contacts:not(.checkable) .check {
    display: none;
  }

  .new-contact-btn {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  .new-contact input {
    width: 100%;
    font-weight: bold;
  }

  .new-contact-buttons {
    grid-area: last-message;
    margin-left: auto;

    .p-button {
      width: 7rem;
    }
  }

  .no-contacts {
    font-style: italic;
    margin: 1rem;
  }
}