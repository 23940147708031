@import "/src/styles/variables"

.App-Web-Phone .call-recording-settings-page
  padding: 1rem

  .description
    margin-bottom: 2rem

  .number-features

    .number
      flex: auto
      transition: all ease 0.3s
      white-space: nowrap

    .status
      position: relative
      padding-left: 20px
      display: inline-block

      &:before
        content: ''
        position: absolute
        left: 2.5px
        top: calc(50% - 10px / 2)
        width: 10px
        height: 10px
        border-radius: 50%
        transition: all ease 0.3s

      &.disconnected
        color: $red-color

        &:before
          background: $red-color
    
      &.connected
        color: green

        &:before
          background: green

    td:hover .status:before
      left: 0
      top: calc(50% - 15px / 2)
      width: 15px
      height: 15px

    .p-column-header-content
      justify-content: center
      text-align: center

    td
      text-align: center

    .p-column-title
      width: 150px

  .loader-component
    padding: 0
    position: relative
    top: 1rem

  .brand-logo
    max-width: 50px
    max-height: 50px
    margin-right: 1rem
    object-fit: scale-down
    object-position: center center