@import "/src/styles/variables.sass";

.App-Web-Phone .dialer-component {
  $border-radius: 1rem;

  flex: auto;
  display: grid;
  overflow: initial;
  align-items: center;
  justify-content: center;

  .content {
    text-align: center;
    min-height: auto;
    margin: 1rem;
    padding: 2rem 1rem;
    border: solid 1px silver;
    border-radius: 0.5rem;
  }

  &.p-dialog {
    border-radius: $border-radius;

    .p-dialog-header {
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  .p-dialog-content {
    border-radius: 0 0 $border-radius $border-radius;
    display: grid;
    grid: 'number' 'keypad' 'call' / 1fr;
    grid-gap: 1rem;
    justify-content: center;
  }

  .src-number {
    font-size: 14px;

    a {
      color: $primary-color;

      &.red {
        color: $red-color;
      }
    }
  }

  .phone-number {
    grid-area: number;
    margin: 1rem 0;

    input {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      border: solid 1px silver;
      margin-top: 4px;
    }
  }

  .keypad {
    grid-area: keypad;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1rem;

    .p-button {
      margin: 10px auto;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      font-size: 18px;
      padding: 0;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white !important;
    }

    .letters {
      font-size: 10px;
      line-height: 6px;
    }
  }

  .btn-call.p-button {
    grid-area: call;
    zoom: 1.25;
    height: 3rem;
    margin: 0 auto;
    background: $green-bg;
    border: solid 1px $green-border-color;
    border-radius: 50%;

    &.p-disabled {
      cursor: not-allowed;
    }
  }
}
