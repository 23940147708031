.App-Web-Phone .header-component
    h2
      font-size: 24px

    header
      padding: 1rem
      display: grid
      grid: 'logo title logout' / auto 1fr auto
      align-items: center

      h1
        margin: 0

      .brand-logo
        grid-area: logo
        max-height: 100px

      .brand-title
        grid-area: title

      .logout-btn
        grid-area: logout
