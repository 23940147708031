@import "/src/styles/variables"

.App-Web-Phone .tools-page-info-tab
  display: flex
  flex-direction: column
  gap: 1rem
  
  .form
    display: flex

  h4
    margin-top: 48px
    font-weight: 500