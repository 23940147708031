@import "styles/variables";

.App-Web-Phone .chat-message-component {
  padding: 1rem;
  max-width: 500px;
  display: grid;
  grid:
    "content  content status" 1fr
    "source   time    status" auto
    / auto auto auto;
  position: relative;

  .message-content {
    grid-area: content;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: solid 1px fade-out($color: $dark-color, $amount: 0.9);
    background: fade-out($color: $dark-color, $amount: 0.9);
    font-size: 16px;
  }

  &.my-message {
    align-self: flex-end;

    .message-content {
      border: solid 1px fade-out($color: $primary-color, $amount: 0.9);
      background: fade-out($color: $primary-color, $amount: 0.85);
    }
  }

  &.failed .message-content {
    border: solid 1px fade-out($color: $red-color, $amount: 0.9);
    background: fade-out($color: $red-color, $amount: 0.85);
  }

  .my-number {
    grid-area: source;
    padding: 0 0.5rem;
  }

  .time {
    grid-area: time;
    text-align: right;
    padding: 0 0.5rem;
  }

  .my-number,
  .time {
    font-size: 12px;
    color: #aaa;
  }

  &.message-type-Call {
    .call-message {
      display: flex;
      align-items: center;
      gap: 1rem;

      svg {
        grid-area: icon;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 0.7;
        margin: 4px 0;
      }
    }

    .direction {
      font-weight: bold;
    }
  }

  &.message-type-Voicemail {
    audio {
      width: 100%;
    }
  }

  .status {
    grid-area: status;
    padding: 8px 4px;

    &.status-failed {
      color: $red-color;
      cursor: pointer;
    }
  }

  .fail-description {
    color: $red-color;
    margin: 0.5rem 0;
    font-size: 14px;
  }

  .delete-msg {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $red-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-bottom: 3px;
  }
  &:not(:hover) .delete-msg {
    display: none;
  }
}
