@import './variables'

.p-dialog, .p-dialog-content
  display: flex
  flex-direction: column
  overflow: auto

.App-Web-Phone, .p-dialog, .p-dialog-content
  .p-component, .p-inputtext
    font-size: 16px

  .p-button
    background: $primary-color
    border-color: $primary-color

    &.p-button-secondary
      background: $txt-white
      border-color: $primary-color
      color: $primary-color

      &:hover
        color: $txt-white
    
    &:focus
      box-shadow: 0 0 0 0.2rem #b5c3ff

    &:enabled:active, &:not(button):not(a):not(.p-disabled):active
      background: #2748d9
      border-color: #2748d9

    &:enabled:hover, &:not(button):not(a):not(.p-disabled):hover
      background: #2f53f5
      border-color: #2f53f5

    &.p-button-outlined
      color: $primary-color

  .p-tabview .p-tabview-nav
    li.p-highlight .p-tabview-nav-link
      color: $primary-color
      border-color: $primary-color

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus,
  .p-inputtext:enabled:focus
    box-shadow: inset 0 0 0 0.2rem rgba(65, 100, 251, 0.15)

  .p-progressbar
    height: 2px
    
    .p-progressbar-value
      background: $primary-color

  .p-field
    margin-bottom: 1rem

  .p-checkbox.p-highlight .p-checkbox-box
    border-color: $primary-color
    background: $primary-color
    color: $txt-white
