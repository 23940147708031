@import "/src/styles/variables"

.App-Web-Phone .activity-page
  padding: 1rem

  .my-number-selection
    display: flex
    align-items: center
    margin: 16px 8px

    label
      margin-right: 1rem
      margin-bottom: 0

  h3
    margin-bottom: 0
    margin-top: 32px

  .activity-table

    &.unread-messages
      width: 300px

      tr:last-child td
        font-weight: bold

    th
      text-align: center
    td
      text-align: right