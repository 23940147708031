@import "/src/styles/variables";

.App-Web-Phone .sms-list-recepients-component,
.p-dialog-visible .sms-list-recepients-component {
  font-size: 16px;
  display: grid;
  grid:
    'search' auto
    'loading' auto
    'contacts' 1fr
    / 1fr;
  height: 100%;
  position: relative;

  .search-component {
    grid-area: search;
  }

  .loader-component {
    grid-area: loading;
    padding: 0.5rem;
  }

  .recepients {
    grid-area: contacts;
    overflow: auto;
    margin: 0;
  }

  .recepient {
    border-bottom: solid 1px $border-color;
    padding: 0.5rem;
    cursor: pointer;
  }

  .new-contact-btn {
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  .new-contact input {
    width: 100%;
    font-weight: bold;
  }

  .new-contact-buttons {
    grid-area: last-message;
    margin-left: auto;

    .p-button {
      width: 7rem;
    }
  }

  .no-contacts {
    font-style: italic;
    margin: 1rem;
  }

  .new-recepient {
    margin-top: 1rem;
    display: flex;

    >div:first-child {
      flex: auto;
    }
  }

  .remove-contact-btn {
    margin-top: 1rem;
  }
}