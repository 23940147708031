@import "/src/styles/variables"

.App-Web-Phone .contact-last-message-component
  display: flex
  align-items: center
  margin-top: 4px
  white-space: nowrap
  overflow: auto

  div
    text-overflow: ellipsis
    overflow: hidden

  .icon
    display: flex
    margin-right: 8px

    svg
      opacity: 0.5

  .rotate
    transform: rotate(180deg)